import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { AuthGuardService } from './shared/service/auth-guard/auth-guard.service';
import { ForbiddenComponent } from './view/error/forbidden/forbidden.component';
import { FormVersionComponent } from './view/settings/form/form-version/form-version.component';
import { FormVersionDetailComponent } from './view/settings/form/form-version-detail/form-version-detail.component';
import { LogoutComponent } from './view/logout/logout.component';
import { ClientSelectionComponent } from './view/client-selection/client-selection.component';
import { PagePermissionGuard } from './shared/service/page-permission-guard/page-permission.guard';
import { ResetPinComponent } from './view/reset-pin/reset-pin.component';
import { InvalidLinkComponent } from './view/error/invalid-link/invalid-link.component';

const routes: Routes = [
  {
    path: 'error/invalid-link',
    component: InvalidLinkComponent,
  },
  {
    path: 'error/forbidden',
    component: ForbiddenComponent,
    title: 'Logbook'
  },
  {
    path: 'reset-pin/:userId/:tokenId',
    component: ResetPinComponent,
  },
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'client-selection',
        component: ClientSelectionComponent,
        data: {
          title: 'client-selection',
          titleKey: 'client-selection',
          icon: 'fas fa-line-columns',
          status: true,
          setPageHeader: true,
        },
      },
      {
        path: 'knowledge-base',
        loadChildren: () => import('./view/knowledge-base/knowledge-base.module').then((m) => m.KnowledgeBaseModule),
      },
      { path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService] },
      {
        path: 'home',
        loadChildren: () => import('./view/home/home.module').then((m) => m.HomeModule),
        data: {
          hidePageHeader: true,
          title: 'home',
          titleKey: 'home',
          icon: '',
          status: true,
          setPageHeader: true,
        },
      },
      {
        path: 'my-tasks',
        loadChildren: () => import('./view/my-tasks/my-tasks.module').then((m) => m.MyTasksModule),
        canActivate: [PagePermissionGuard],
      },
      {
        path: 'settings',
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'forms',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/form/form.module').then((m) => m.FormModule),
          },
          {
            path: 'form/:formId',
            component: FormVersionComponent,
            canActivate: [PagePermissionGuard],
            data: {
              title: 'form-versions',
              titleKey: 'form-versions',
              icon: 'fas fa-line-columns',
              status: true,
              setPageHeader: true,
            },
          },
          {
            path: 'form/:formId/:versionId',
            component: FormVersionDetailComponent,
            canActivate: [PagePermissionGuard],
            data: {
              title: 'form-version-detail',
              titleKey: 'form-version-detail',
              icon: 'fas fa-line-columns',
              status: true,
              setPageHeader: true,
            },
          },
          {
            path: 'roles',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/roles/roles.module').then((m) => m.RolesModule),
          },
          {
            path: 'workflows',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/workflows/workflows.module').then((m) => m.WorkflowsModule),
          },
          {
            path: 'client',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/client/client.module').then((m) => m.ClientModule),
            data: {
              noOverlap: true,
              setPageHeader: true,
            },
          },
          {
            path: 'users',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/users/users.module').then((m) => m.UsersModule),
          },
          {
            path: 'scopes',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/scopes/scopes.module').then((m) => m.ScopesModule),
          },
          {
            path: 'master-data',
            canActivate: [PagePermissionGuard],
            loadChildren: () =>
              import('./view/settings/master-data/master-data.module').then((m) => m.MasterDataModule),
          },
          {
            path: 'logbooks',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/logbook/logbook.module').then((m) => m.LogbookModule),
          },
          {
            path: 'scopes#form-scopes',
            loadChildren: () => import('./view/settings/scopes/scopes.module').then((m) => m.ScopesModule),
          },
          {
            path: 'scopes#logbook-scopes',
            loadChildren: () => import('./view/settings/scopes/scopes.module').then((m) => m.ScopesModule),
          },
          {
            path: 'logs-table-configuration',
            canActivate: [PagePermissionGuard],
            loadChildren: () =>
              import('./view/settings/logs-table-configuration/logs-table-configuration.module').then(
                (m) => m.LogsTableConfigurationModule,
              ),
          },
          {
            path: 'activity-types',
            canActivate: [PagePermissionGuard],
            loadChildren: () =>
              import('./view/settings/activity-type/activity-type.module').then((m) => m.ActivityTypeModule),
          },
          {
            path: 'states',
            canActivate: [PagePermissionGuard],
            loadChildren: () => import('./view/settings/states/states.module').then((m) => m.StatesModule),
          },
        ],
      },
      {
        path: 'reports/my-form-entry-reports',
        loadChildren: () => import('./view/reports/my-reports/my-reports.module').then((m) => m.MyReportsModule),
        canActivate: [PagePermissionGuard],
      },
      {
        path: 'reports/form-entries',
        loadChildren: () => import('./view/reports/form-entries/form-entries.module').then((m) => m.FormEntriesModule),
        canActivate: [PagePermissionGuard],
      },
      {
        path: 'reports/logs',
        loadChildren: () => import('./view/reports/logs-reports/logs-reports.module').then((m) => m.LogsReportsModule),
        canActivate: [PagePermissionGuard],
      },
      {
        path: 'audit-trail',
        canActivate: [AuthGuardService, PagePermissionGuard],
        loadChildren: () => import('./view/audit-trail/audit-trail.module').then((m) => m.AuditTrailModule),
      },
      {
        path: 'reports/form-templates',
        canActivate: [AuthGuardService, PagePermissionGuard],
        loadChildren: () =>
          import('./view/reports/form-templates/form-templates-module').then((m) => m.FormTemplatesModule),
      },
      {
        path: 'reports/logbook-templates',
        canActivate: [AuthGuardService, PagePermissionGuard],
        loadChildren: () =>
          import('./view/reports/logbook-templates/logbook-templates-module').then((m) => m.LogbookTemplatesModule),
      },
      {
        path: 'my-tasks#logs-form-entries',
        loadChildren: () => import('./view/my-tasks/my-tasks.module').then((m) => m.MyTasksModule),
      },
      {
        path: 'my-tasks#logbook-tasks',
        loadChildren: () => import('./view/my-tasks/my-tasks.module').then((m) => m.MyTasksModule),
      },
      {
        path: 'my-tasks#form-tasks',
        loadChildren: () => import('./view/my-tasks/my-tasks.module').then((m) => m.MyTasksModule),
      },
      {
        path: 'my-tasks#master-data',
        loadChildren: () => import('./view/my-tasks/my-tasks.module').then((m) => m.MyTasksModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
